import anon from "@/assets/images/main/anon.png"
import phone from "@/assets/images/main/phone.png"
import quastion from "@/assets/images/main/quastion.png"

import lovoo from "@/assets/images/main/logos/lovoo.png"
import okcupid from "@/assets/images/main/logos/okcupid.png"
import mico from "@/assets/images/main/logos/mico.png"
import heart from "@/assets/images/main/logos/heart.png"
import zoosk from "@/assets/images/main/logos/zoosk.png"

import tech from "@/assets/images/main/logos/tech.png"
import fox from "@/assets/images/main/logos/fox.png"
import bbc from "@/assets/images/main/logos/bbc.png"
import cnbc from "@/assets/images/main/logos/cnbc.png"
import cnet from "@/assets/images/main/logos/cnet.png"
import inc from "@/assets/images/main/logos/inc.png"

import lovooC from "@/assets/images/main/logos-color/lovoo.webp"
import okcupidC from "@/assets/images/main/logos-color/okcupidc.png"
import jdateC from "@/assets/images/main/logos-color/jdatec.png"
import dateHookC from "@/assets/images/main/logos-color/datehookc.png"
import datingPartners from "@/assets/images/main/logos-color/datingpartners.png"
import micoC from "@/assets/images/main/logos-color/mico.png"
import heartsC from "@/assets/images/main/logos-color/hearts.png"
import lavaC from "@/assets/images/main/logos-color/lava.png"
import sugarC from "@/assets/images/main/logos-color/sugar.png"
import zooskC from "@/assets/images/main/logos-color/zoosk.png"



import sasha from "@/assets/images/main/our-mission/team/sasha.png"


import foxLogo from "@/assets/images/landing/svg/fox-color.svg";
import wrifLogo from "@/assets/images/landing/svg/wrif-color.svg";
import marketwatchLogo from "@/assets/images/landing/svg/marketwatch-color.svg";
import wralLogo from "@/assets/images/landing/svg/wral-color.svg";
import financeLogo from "@/assets/images/landing/svg/finance-color.svg";
import globeLogo from "@/assets/images/landing/svg/globe-color.svg";
import tretonianLogo from "@/assets/images/landing/svg/tretonian-color.svg";
import newsLogo from "@/assets/images/landing/svg/news-color.svg";
import breakingLogo from "@/assets/images/landing/svg/breaking-color.svg";
import tmcLogo from "@/assets/images/landing/svg/tmc-color.svg";


import cpolish from "@/assets/images/landing/svg/polish-hearts.svg";
import cjdate from "@/assets/images/landing/svg/jdate.svg";
import cparents from "@/assets/images/landing/svg/for-parents.svg";
import clovo from "@/assets/images/landing/svg/lovoo.svg";
import cmico from "@/assets/images/landing/svg/mico.svg";
import clavalife from "@/assets/images/landing/svg/lavalife.svg";
import cokcupid from "@/assets/images/landing/svg/okcupid.svg";
import czoosk from "@/assets/images/landing/svg/zoosk.svg";

import datingbox from "@/assets/images/main/boxes/dating-sharow-left.png"
import socialbox from "@/assets/images/main/boxes/social-sharow-left.png"
import camsbox from "@/assets/images/main/boxes/cams-shadow-left.png"
import locatorbox from "@/assets/images/main/boxes/locator-sharow-left.png"
import tinderbox from "@/assets/images/main/boxes/tinder-shadow-left.png"
import xxxbox from "@/assets/images/main/boxes/xxx-sharow-left.png"

import dating from "assets/images/main/boxes/products/dating.png";
import social from "assets/images/main/boxes/products/social.png";
import locator from "assets/images/main/boxes/products/locator.png";
import tinder from "assets/images/main/boxes/products/tinder.png";
import xxx from "assets/images/main/boxes/products/xxx.png";
import cams from "assets/images/main/boxes/products/cams.png";

import bodya from "@/assets/images/Team/mate-card_bodya.png"
import click from "@/assets/images/Team/mate-card_click.png"
import duckduck from "@/assets/images/Team/mate-card_duckduck.png"
import mrspelik from "@/assets/images/Team/mate-card_mrspelik.png"
import simm from "@/assets/images/Team/mate-card_simm.png"
import tokarev from "@/assets/images/Team/mate-card_tokarev.png"
import rafal from "@/assets/images/Team/rafal.png"
import igor from "@/assets/images/Team/propsht.png"
import vic from "@/assets/images/Team/mate-card_vic.png"



export default {
    data() {
        return {
            sites: [lovoo, okcupid, mico, heart, zoosk],
            featured: [tech, fox, bbc, cnbc, cnet, inc],
            newOldFeatured: [cpolish, cjdate, cparents, clovo, cmico, clavalife, cokcupid, czoosk],
            newFeatured: [foxLogo, wrifLogo, marketwatchLogo, wralLogo, financeLogo, tretonianLogo, newsLogo, tmcLogo],
            processTabs: [{
                    title: 'ENTER PARTNER’S FIRST & LAST NAME \n' +
                        'INCLUDING EMAIL',
                    text: 'Then, enter your info and click SEARCH. We only request your full name and email address upon completion to prepare and send your personalized search report. We do not disclose user information to any third party with our <strong>best website to find out if a spouse is cheating</strong>. You will be prompted to select which package of services you want us to scan: Dating/Alternative Lifestyle Sites, Social Media & Mobile Chat/Hook-up Apps, Listing Locator for detailed People, Phone, Address and Email info, Tinder Blaster, XXX Websites or Live Adult Streams.',
                    step: 1,
                },
                {
                    title: 'Technical Stuff',
                    text: `Our specially designed algorithms scan online dating platforms or social media and mobile application databases – one at a time – checking for active profiles attached to the email address(es) you have provided that your partner uses. We continually update our algorithms to guarantee the integrity of our <strong>cheater finder</strong>.`,
                    step: 2,
                },
                {
                    title: 'LOOK & LEARN',
                    text: `Our search process typically takes only a few minutes to <strong>find cheating wives</strong>, husbands, boyfriends, or girlfriends. You will be redirected to a secure web page where you will be updated on search progress in real-time. If you don’t want to wait around, we will also email confirmation once your report is ready for viewing on our website. You can even print a PDF of your report if you need a hard copy.`,
                    step: 3,
                },
                {
                    title: 'VIEW THE COMPLETED RESULTS',
                    text: 'Depending on which services you selected for your search on our <strong>cheater finder</strong>, your personalized report will list all services that were scanned. Any platform or app with an active account containing your partner’s information will be flagged as ACCOUNT FOUND!',
                    step: 4,
                }

            ],
            boxWidthItem: [{
                    title: 'E-BOOKS',
                    text: 'Researched and developed to provide practical and empowering insights, these books can help in evaluating your relationship and finding the right course of action. Only you know if your relationship is worth fighting for, even if your partner has been unfaithful. We provide you the know how to protect yourself from heartbreak and to keep the gleam in your and your partner’s eye. When you know the secrets to keeping the passion alive, your partner will find you irresistible! Free titles with every purchase or upgrade! Select “E-BOOKS” on our PRODUCTS & SERVICES page to browse our e-Book titles.\n',
                    icon: 'book',
                    btn: 'BUY E-BOOKS ',
                    i: 'book'
                },

                {
                    title: 'in-depth checks',
                    text: 'If you have discovered that your partner has not been honest with you about their online activity, particularly if you are in a relatively new relationship, they may be hiding other information as well. For your protection, we offer a comprehensive background check service. We promise to deliver facts to put your mind at ease. You deserve to know who you are involved with. We can provide verified marital and family status, criminal and judicial records. Our process is simple, fast and reliable. To run a thorough background check on your partner, select IN-DEPTH CHECKS on our PRODUCTS & SERVICES page.',
                    icon: 'search',
                    btn: 'START IN-DEPTH CHECKS',
                    i: 'search'
                },

                {
                    title: 'mobile/pc monitoring',
                    text: 'Protect your family and your business by keeping tabs on your child’s or employee’s web browsing, location and cell phone activity. Make sure you child is not endangering them self or your home by sharing sensitive and/or personal identifiable information via apps or websites. Ensure that your employees are not wasting time or valuable resources while using devices attached to your home or business or placing you at risk.',
                    icon: 'mobile-alt',
                    btn: 'START MOBILE/PC MONITORING ',
                    i: 'check-circle'
                }
            ],
            boxWidthItemMain: [{
                    title: 'WE CATCH INFIDELITY ONLINE',
                    text: 'iFindCheaters is the <strong>best website to find out if your spouse is cheating</strong>. With advanced search technology, it helps you catch cheaters. If your partner’s account exists, we will find it. Within minutes view your partner’s online dating presence, so you can monitor their activity and protect yourself.',
                    icon: 'mobile-alt',
                    btn: 'BUY E-BOOKS ',
                    i: 'book'
                },

                {
                    title: '100% ANONYMOUS <br/> SERVICE',
                    text: 'iFindCheaters - The <strong>cheater finder</strong> lets you discover if your partner is cheating without revealing your identity. We respect your privacy. iFindCheaters searches are 100% confidential – we will never disclose your user information or alert your partner.',
                    icon: 'user-secret',
                    btn: 'START IN-DEPTH CHECKS',
                    i: 'search'
                },

                {
                    title: 'how we find <br/>them',
                    text: 'By directly accessing popular Dating/Alternative Lifestyle Sites, Social Media & Mobile Chat/Hook-Up Apps, or monitoring XXX Websites or Live Adult Streams, we can detect active profiles your partner maintains and help you <strong>find cheating wives</strong> or husbands. We provide you with that information so you can follow up and know who you are involved with.',
                    icon: 'question-circle',
                    btn: 'START MOBILE/PC MONITORING ',
                    i: 'fal check-circle'
                }
            ],
            sampleReportUser: [{
                name: 'John Smith',
                email: 'yourname@gmail.com',
                reportFor: 'Jane Smith',
                reportNumber: 2592,
                platform: 'DATING/ALTERNATIVE LIFESTYLE SITES'
            }],
            sampleSearchPositions: [{
                    logo: lovooC,
                    isFound: true,
                    email: 'name@email.com',
                    service: 'lovoo.com'
                },
                {
                    logo: okcupidC,
                    isFound: false,
                    email: '',
                    service: ''
                },
                {
                    logo: jdateC,
                    isFound: true,
                    email: 'name@email.com',
                    service: 'jdate.com'
                },
                {
                    logo: dateHookC,
                    isFound: false,
                    email: '',
                    service: ''
                },
                {
                    logo: datingPartners,
                    isFound: true,
                    email: 'name@email.com',
                    service: 'datingforparents.com'
                },
                {
                    logo: micoC,
                    isFound: false,
                    email: '',
                    service: ''
                },
                {
                    logo: heartsC,
                    isFound: false,
                    email: '',
                    service: ''
                },
                {
                    logo: lavaC,
                    isFound: true,
                    email: 'name@email.com',
                    service: 'lavalife.com'
                },
                {
                    logo: sugarC,
                    isFound: true,
                    email: 'name@email.com',
                    service: 'sugardaddyforme.com'
                },
                {
                    logo: zooskC,
                    isFound: false,
                    email: '',
                    service: ''
                },
            ],
            comments: [{
                    comment: 'I have run two searches on iFindCheaters.com, but no positive results so far…maybe she is honest. However, I do think it’s an easy to use, and the pricing is fair too. They allow you to pay for as little as a single search.',
                    author: 'By Lucy On 08/21/18'
                },
                {
                    comment: 'I have run two searches on iFindCheaters.com, but no positive results so far…maybe she is honest. However, I do think it’s an easy to use, and the pricing is fair too. They allow you to pay for as little as a single search.',
                    author: 'By Lucy On 08/21/18'
                },
                {
                    comment: 'I have run two searches on iFindCheaters.com, but no positive results so far…maybe she is honest. However, I do think it’s an easy to use, and the pricing is fair too. They allow you to pay for as little as a single search.',
                    author: 'By Lucy On 08/21/18'
                },
                {
                    comment: 'I have run two searches on iFindCheaters.com, but no positive results so far…maybe she is honest. However, I do think it’s an easy to use, and the pricing is fair too. They allow you to pay for as little as a single search.',
                    author: 'By Lucy On 08/21/18'
                },
                {
                    comment: 'I have run two searches on iFindCheaters.com, but no positive results so far…maybe she is honest. However, I do think it’s an easy to use, and the pricing is fair too. They allow you to pay for as little as a single search.',
                    author: 'By Lucy On 08/21/18'
                },
                {
                    comment: 'I have run two searches on iFindCheaters.com, but no positive results so far…maybe she is honest. However, I do think it’s an easy to use, and the pricing is fair too. They allow you to pay for as little as a single search.',
                    author: 'By Lucy On 08/21/18'
                },
                {
                    comment: 'I have run two searches on iFindCheaters.com, but no positive results so far…maybe she is honest. However, I do think it’s an easy to use, and the pricing is fair too. They allow you to pay for as little as a single search.',
                    author: 'By Lucy On 08/21/18'
                },

            ],
            ourTeam: [{
                    name: 'Rafal Orlik',
                    img: rafal,
                    position: 'ceo/founder',
                    about: 'Is a loving dad, husband, and a visionary entrepreneur. His own painful experience with infidelity led him to create iFindCheaters so that others could be spared the heartache he suffered. Since 2016 he has been investing in the application of technology to find ways to improve lives.',
                    fb: 'https://www.facebook.com/profile.php?id=100000349326091',
                    lin: 'https://www.linkedin.com/in/rafal-orlik-11368b181/',
                    tw: '',
                    inst: '',
                    social: false,
                },
                {
                    name: 'Victoria ANDRE KING',
                    img: vic,
                    position: 'Author',
                    about: 'Is an educator and published author who has years of experience in both Europe and the United States. She brings her background in psychology research to creating informative ebooks and articles for iFindCheaters to offer our customers useful insights for improving their relationships.',
                    fb: '',
                    lin: 'https://www.linkedin.com/in/victoria-andre-king-79516426',
                    tw: '',
                    inst: '',
                    social: false,
                },
                {
                    name: 'YAROSLAV KRUTIKOV',
                    img: mrspelik,
                    position: 'CTO',
                    about: 'Highly accomplished, results oriented Chief Technical Officer with over 10 years of development and technical leadership experience. Have over 8 years of experience with PHP, JS languages and frameworks and 6 years experience with Ruby and Ruby On Rails. Experienced in team-building and management expertise with focus on process involvement to enhance IT development practices. Proven track record of developing and implementing enterprise-class scalable data management solutions.',
                    fb: '',
                    lin: '',
                    tw: '',
                    inst: '',
                    social: false,
                },

                {
                    name: 'IGOR PROPSHT',
                    img: igor,
                    position: 'PRODUCT MANAGER',
                    about: 'Highly accomplished, results oriented Product Manager with over 10 years of managerial experience and people leadership experience. Have over 5 years IT managerial experience. Have a passion solve problems and how to organize and admin the project. Have experience in solving complex business problems to deliver operational excellence. Desire to learn and grow professionally it`s what I love.',
                    fb: '',
                    lin: 'https://www.linkedin.com/in/igor-propsht/',
                    tw: '',
                    inst: '',
                    social: false,
                },

                {
                    name: 'NICK Kudelia',
                    img: duckduck,
                    position: 'FULL-STACK',
                    about: 'Software engineer with over 6 years of development. Using in work PHP language, because prefer focus on process server-side part of project. Proven track record know how to choose the right tool, where I can choose native PHP, or where I can choose framework. Responsible and punctual, I want to learn new things and grow in new areas. Highly organized and efficient in fast-paced multitasking environments; able to prioritize effectively to accomplish objectives with creativity, enthusiasm and humor.',
                    fb: '',
                    lin: '',
                    tw: '',
                    inst: '',
                    social: false,
                },
                {
                    name: 'Konstantin Peschanov',
                    img: simm,
                    position: 'FULL-STACK',
                    about: 'Full-stack web developer with over 3 years of experience with main javascript technologies. Developed, enhanced, and maintained websites using modern tools and frameworks. Self-motivated, meticulous, and possessing a strong willingness to learn about new technologies and trends. Have a passion for coding, building interactive experiences, and solving problems.',
                    fb: '',
                    lin: '',
                    tw: '',
                    inst: '',
                    social: false,
                },
                {
                    name: 'OLEG bodyansky',
                    img: bodya,
                    position: 'BACK-END',
                    about: 'PHP software engineer, with over 6 years  web-developing since 2015. Had experience as system analyst in the accounting and management of trading enterprises software until 2015. The main task was to analyze and develop new modules. Fast and clear understanding of customers preferences. Development of clear and working code. I can work in a team and independently. My strong point is back-end side and using PHP language.',
                    fb: '',
                    lin: '',
                    tw: '',
                    inst: '',
                    social: false,
                },
                {
                    name: 'Alex Cherniavskyi',
                    img: click,
                    position: 'Front-end',
                    about: 'Front-end web developer with over 3 years of experience planning, coding and maintaining websites. My code is valid, clean, and understandable. I always strive for self-development and improve my skills in web development by studying new technologies.',
                    fb: '',
                    lin: 'www.linkedin.com/in/alexandr-cherniavskiy',
                    tw: '',
                    inst: 'https://instagram.com/sasha_cherniavsky',
                    social: false,
                },
                {
                    name: 'VICTOR',
                    img: tokarev,
                    position: 'UX/UI DESIGNER',
                    about: 'Highly motivated, creative, and customer-focused UX/UI designer with a passion for technology. Enjoy taking on a challenge and constantly looking for an opportunity to learn and grow. As a detail oriented individual with a passion for excellence, I am analyzing client`s needs and implementing the best designed solution. Experienced in working with Figma, Ps, Ai, Ae, Blender3D, Protopie, creating web pages and web mobile.',
                    fb: '',
                    lin: '',
                    tw: '',
                    inst: '',
                    social: false,
                },

            ],
            ourMissionProducts: [{
                    title: 'DATING/ALTERNATIVE <br>LIFESTYLE SITES',
                    startingFrom: '$9.99',
                    text: 'The list of sites that our algorithms scan is growing constantly. Find out whether your partner has active profiles across a multitude of popular dating and alternative lifestyle platforms.',
                    sale: true,
                    box: dating,
                    lnk: '/account/search-credits/',
                },
                {
                    title: 'social media & mobile <br> chat/hook-up apps',
                    startingFrom: '$9.99',
                    text: 'Find out if your partner is being honest about how they spend their free time. This service reveals active accounts on social media platforms with dating components as well as adult chat and booty-call apps.',
                    sale: true,
                    box: social,
                    lnk: '/account/search-credits/',
                },
                {
                    title: 'listing locator',
                    startingFrom: '$7.99',
                    text: 'You have a right to know if the person you are seeing is being honest about important information. With this service you can verify identity, addresses and phone numbers as well as marital and family status.',
                    sale: false,
                    box: locator,
                    lnk: '/account/listing-locator/',
                },
                {
                    title: 'live adult streams',
                    startingFrom: '$14.99',
                    text: 'Find out whether your love interest subscribes to adult streams. Sex is an important part of a healthy relationship so it is important to know if your partner has interests you may not be comfortable with. Not to mention these streams can be pricey.',
                    sale: false,
                    box: cams,
                    lnk: '/account/search-credits/',
                },
                {
                    title: 'xxx websites',
                    startingFrom: '$14.99',
                    text: 'Looking at porn does not automatically make someone a bad person but knowing what someone is into looking at can tell you a lot about them. Save yourself from potentially unpleasant surprises up front with this search tool.',
                    sale: false,
                    box: xxx,
                    lnk: '/account/search-credits/',
                },
                {
                    title: 'tinder blaster',
                    startingFrom: '$14.99',
                    text: 'Find out whether your partner is a swiper before they swipe your heart. This service offers different profile search options to help you discover whether your partner is using an active Tinder profile to meet others.',
                    sale: false,
                    box: tinder,
                    lnk: '/account/tinder-blaster-credits/'
                },

            ],
            ourMissionProductsP: [{
                    title: 'DATING/ALTERNATIVE <br>LIFESTYLE SITES',
                    startingFrom: '$9.99',
                    text: 'The list of sites that our algorithms scan is growing constantly. Find out whether your partner has active profiles across a multitude of popular dating and alternative lifestyle platforms',
                    popular: true,
                    //img: beautiful
                },
                {
                    title: 'social media & mobile <br> chat/hook-up apps',
                    startingFrom: '$9.99',
                    text: 'Find out if your partner is being honest about how they spend their free time. This service reveals active accounts on social media platforms with dating components as well as adult chat and booty-call apps.',
                    popular: true,
                    //img: keephimhot
                },
                {
                    title: 'listing locator',
                    startingFrom: '$9.99',
                    text: 'You have a right to know if the person you are seeing is being honest about important information. With this service you can verify identity, addresses and phone numbers as well as marital and family status.',
                    popular: false,
                    //img: lookbefore
                },
                {
                    title: 'live adult streams',
                    startingFrom: '$9.99',
                    text: 'Find out whether your love interest subscribes to adult streams. Sex is an important part of a healthy relationship so it is important to know if your partner has interests you may not be comfortable with. Not to mention these streams can be pricey.',
                    popular: false,
                    //img:mating
                },
                {
                    title: 'xxx websites',
                    startingFrom: '$9.99',
                    text: 'Looking at porn does not automatically make someone a bad person but knowing what someone is into looking at can tell you a lot about them. Save yourself from potentially unpleasant surprises up front with this search tool.',
                    popular: false,
                    //img:overcoming
                },
                {
                    title: 'tinder blaster',
                    startingFrom: '$9.99',
                    text: 'Find out whether your partner is a swiper before they swipe your heart. This service offers different profile search options to help you discover whether your partner is using an active Tinder profile to meet others.',
                    popular: false,
                    // img: WhatDoYoWant
                },

            ],
            feedBackIssueOptions: [{
                    option: 'General Support Issues',
                    id: 1,
                },
                {
                    option: 'Billing Questions',
                    id: 2,
                },
                {
                    option: 'Canceling Subscription Issues',
                    id: 3,
                },
                {
                    option: 'Refund Request',
                    id: 4,
                },
                {
                    option: 'View Report',
                    id: 5,
                },
                {
                    option: 'Remove My Info',
                    id: 6,
                },
                {
                    option: 'Feedback',
                    id: 7,
                },
                {
                    option: 'Press',
                    id: 8,
                },
                {
                    option: 'Other',
                    id: 9,
                },
            ],
        }
    }
}